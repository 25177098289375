<template>
  <div class='product'>
    <div class="image">
      <img class="img" src="@/assets/center/20170427025501642.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.image{
  width: 100%;
  margin-bottom: 0.7463rem;
  .img{
    width: 100%;
  }
}
</style>
